<template>
  <div>
    <v-overlay
      :absolute="absolute"
      :opacity="opacity"
      :z-index="zIndex"
    >
      <div class="container">
        <v-progress-circular
          v-if="loading"
          indeterminate
          color="white"
          :size="50"
          width="2"
        />
        <br>
        <h1 class="message-color">
          {{ message }}
        </h1>
      </div>
    </v-overlay>
  </div>
</template>
  <script>

      export default {
        name: 'InvitedUserSignupProcess',
        data () {
          return {
            loading: false,
            message: '',
            absolute: false,
            opacity: 0.6,
            zIndex: 5,
          };
        },
        mounted () {
          this.startRegistrationProcess();
        },
        methods: {
          async startRegistrationProcess () {
            this.message = 'Verifying Link...';
            this.loading = true;
            const inviteId = this.$route.params.invitaionKey;
            await this.$store.dispatch('registration/verifyInvitationLink', inviteId).then(response => {
              this.$router.push({ path: `/signup/${inviteId}` });
            }).catch((error) => {
              this.loading = false;
              this.message = error.response.data.message;
              setTimeout(() => {
                this.$router.push({ name: 'Login' });
              }, 3000);
            });
          },
        },
      };
    </script>
    <style scoped>
    .message-color {
      color: white;
    }
    .container{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    </style>
